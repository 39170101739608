/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import { Row, Col, Card, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

import Auth from "../services/Auth";
import config from "../config";
import MapComponent from "../components/Map";

function Map() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (info) => {
    const fileList = [...info.fileList];
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      if (
        file.name
          .split(".")
          .pop()
          .match(/(csv)$/)
      ) {
        setFile(file);
        setFileName(file.name);
        setErrors({});
      } else {
        setFile(null);
        setFileName("");
        setErrors({
          file: `The file type .${file.name.split(".").pop()} is not supported`,
        });
      }
    }
  };

  const handleSubmit = async () => {
    console.log("hello");
    if (!file) {
      setErrors({ file: "Please select a file" });
      return;
    }

    setUploading(true);
    let playlistData = new FormData();
    playlistData.append("file", file);

    await axios
      .post(`${config.API_URL}/admin/customer`, playlistData, {
        headers: {
          Authorization: Auth.getToken(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((playlistResponse) => {
        console.log(playlistResponse);
        if (playlistResponse.status === 200) {
          message.success("File has been uploaded successfully.");
          setFileName("");
          setFile(null);
          setUploading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        setUploading(false);
        message.error("Failed to upload file.");
      });
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Map"
              extra={
                <Row gutter={[16, 0]}>
                  <Col>
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleImageChange}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    {errors.file && (
                      <span style={{ color: "red" }}>{errors.file}</span>
                    )}
                    <h4>{fileName}</h4>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={uploading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              }
            >
              <div className="table-responsive">
                <MapComponent />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Map;
