/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

import {
  Card,
  Col,
  Row,
  message,
  Button,
  Table,
  Tag,
  Space,
  Popconfirm,
  Modal,
  Form,
  Input,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import config from "../config";
import axios from "axios";

function Home() {
  const [userData, setUserData] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addUserLaoding, setAddUserLoading] = useState(false);

  const [addForm] = Form.useForm();

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    },
  });
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${config.API_URL}/admin/user/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const newdata = userData.filter((i) => {
          return i._id !== id;
        });
        message.success("User has been deleted successfully.");
        setUserData(newdata);
      });
  };

  const columns = [
    {
      title: "ID",
      key: "index",
      render: (_, d, index) => index + 1,
    },
    {
      title: "EMAIL",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "ACTION",
      key: "action",
      dataIndex: "role",
      render: (role, { _id }) => (
        <>
          {role === "admin" ? (
            <Tag color="green" tag={role}>
              {role}
            </Tag>
          ) : (
            <Space>
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => handleDelete(_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" danger>
                  <DeleteOutlined style={{ fontSize: "16px" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const handleSave = () => {
    setAddUserLoading(true);
    addForm
      .validateFields()
      .then(async (values) => {
        console.log(values);
        const formdata = {
          email: values.email,
          password: values.password,
          confirm_password: values.confirm,
          role: "user",
        };
        await axios(`${config.API_URL}/auth/register`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          data: formdata,
        })
          .then((response) => {
            if (response.data.success) {
              fetchUsers();
              message.success("User Added");
              setAddUserModal(false);
              setAddUserLoading(false);
            }
          })
          .catch((e) => {
            setAddUserLoading(false);
            if (e.response.data.error?.includes("Duplicate")) {
              message.error("This Email is already in use.");
            } else {
              message.error(e.response.data.error);
            }
          })

          .catch((error) => {
            throw error;
          });
      })
      .catch(() => setAddUserLoading(false));
  };

  const fetchUsers = async () => {
    console.log("called");
    await axios.get(`${config.API_URL}/admin/user`, {}).then((response) => {
      setUserData(response.data.data);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users"
              extra={
                <Button
                  onClick={() => setAddUserModal(true)}
                  icon={<PlusOutlined />}
                >
                  Add User
                </Button>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={userData}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Add User"
        visible={addUserModal}
        confirmLoading={addUserLaoding}
        onOk={handleSave}
        onCancel={() => setAddUserModal(false)}
      >
        <Form layout="vertical" form={addForm}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email address" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 6,
                message: "Please increase your Password length.",
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Home;
