import React from "react";
import { compose } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import config from "../../config";

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      options={{ gestureHandling: "greedy" }}
      defaultZoom={9}
      defaultCenter={{
        lat: props.userpos !== null && props.userpos.latitude,
        lng: props.userpos !== null && props.userpos.longitude,
      }}
    >
      <Marker
        icon="https://img.icons8.com/dusk/30/000000/worker-male.png"
        position={{
          lat: props.userpos !== null && props.userpos.latitude,
          lng: props.userpos !== null && props.userpos.longitude,
        }}
      />
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
        {props.markers.map((marker) => {
          const onClick = props.onClick.bind(this, marker);
          {
            console.log(marker.mark);
          }
          return (
            <Marker
              key={marker.id}
              onClick={onClick}
              position={{
                lat: marker.location.coordinates[1],
                lng: marker.location.coordinates[0],
              }}
            >
              {props.selectedMarker === marker && (
                <InfoWindow>
                  <div>
                    <h6>{marker.accountname}</h6>
                    <p>
                      <strong>Phone:</strong>{" "}
                      <a href={`tel:${marker.phone}`}>{marker.phone}</a>
                    </p>
                    <p>
                      <strong>Primary contact:</strong> {marker.primaryContact}{" "}
                    </p>

                    <p>
                      <a href={marker.Flink ? `${marker.Flink}` : "#"}>
                        {marker.address.split(",", 3)[0]}
                        <br />
                        {marker.address.split(",", 3)[1]},
                        {marker.address.split(",", 3)[2]}
                      </a>
                    </p>
                    <br />
                    <p>
                      <strong>Company / Division:</strong> {marker.division}
                    </p>
                    <p>
                      <strong>Account Type:</strong> {marker.accountType}
                    </p>
                    <p>
                      <strong>Sales Rep:</strong> {marker.activeSalesRep}
                    </p>
                    <p>
                      <strong>Team:</strong> {marker.team}
                    </p>
                    <br />
                    <p>
                      <strong>On-Site Inspection:</strong>{" "}
                      {marker.inspectiondate}
                    </p>
                    <p>
                      <strong>Customer Srvc Call:</strong> {marker.srvcall}
                    </p>
                    <p>
                      <strong>Opportunity:</strong> {marker.opportunityname}
                    </p>
                    <p>
                      <strong>RS # Days/Week:</strong>{" "}
                      {marker.rsNumberofdaysofweek}
                    </p>
                    <p>
                      <strong>Days of Cleaning:</strong> {marker.daysofclean}
                    </p>
                    <p>
                      <strong>Days of Germ Buster:</strong> {marker.daysofgerm}
                    </p>
                    <p>
                      <strong>RS - Last Service Date:</strong>{" "}
                      {marker.rsLastServiceDate}
                    </p>
                    <p>
                      <strong>RS-SS - Last Service Date:</strong>{" "}
                      {marker.rsSSLastServiceDate}
                    </p>
                    <p>
                      <strong>RS-SS-GB - Last Service Date:</strong>{" "}
                      {marker.rsSSGBLastSeviceDate}
                    </p>
                    <p>
                      <strong>Recent QPR Date Recent ECC - QPR:</strong>{" "}
                      {marker.recentECCQPR}
                    </p>
                    <p>
                      <strong>Recent Client - QPR:</strong>{" "}
                      {marker.recentClientQPR}
                    </p>

                    {console.log(marker)}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </MarkerClusterer>
    </GoogleMap>
  );
});

function MainMap(props) {
  const [loading, setLoading] = useState(true);
  const [shelters, setShelters] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    await axios
      .get(`${config.API_URL}/admin/customer`)
      .then((res) => {
        setShelters(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleClick = (marker, _) => {
    setSelectedMarker(marker);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <h1>Loading...</h1>;
  }
  return (
    <MapWithAMarker
      selectedMarker={selectedMarker}
      markers={shelters}
      userpos={props.pos}
      onClick={handleClick}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9zFtmTX9HWiz3ofCHHoDR2zEAYCmQBCY"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}

export default MainMap;
