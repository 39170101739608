import React, { useState, useEffect } from "react";
import MainMap from "./MainMap";

function MapComponent() {
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition((p) => {
      setPosition(p.coords);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <h1>Loading...</h1>;
  }
  return <MainMap pos={position} />;
}

export default MapComponent;
